import { Box } from "@chakra-ui/react";

import TreeStepsSection from "./TreeStepsSection";
import MainSection from "./MainSection";
import CalendarPartSection from "./CalendarPartSection";
import FeaturesSection from "./FeaturesSection";
import PriceSection from "./PriceSection";
import DescriptionSection from "./DescriptionSection";
import Footer from "./Footer";
import Navbar from "./Navbar";
import UsageSections from "./Sections";
import Contacts from "./Contacts";

export const mainColor = "#007188";
export const darkColor = "#024856";
export const lightColor = "#5AB3C5";
export const greyColor = "#E9F0F1";

const LandingPage = () => {
  return (
    <Box bg="gray.50" color="gray.800" scrollBehavior="smooth">
      {/* Header */}
      <Navbar />

      {/* Hero Section */}

      <MainSection />

      {/* Dynamic Calendar Image Section */}
      <CalendarPartSection />

      {/* Integration Section */}
      <DescriptionSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* 3 steps description section */}
      <TreeStepsSection />

      {/* For whose Section */}
      <UsageSections />
      {/* Pricing Section */}
      <PriceSection />

      {/* Contacts Section */}
      <Contacts />

      <Footer />
    </Box>
  );
};

export default LandingPage;
