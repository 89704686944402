import {
  Box,
  Stack,
  Text,
  Link,
  Flex,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import logo from "../../assets/logo/logo_white.png";
import { lightColor } from "./LandingPage";

const Footer = () => {
  return (
    <Box bg="gray.900" color="gray.200" py={10}>
      <Flex
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={6}
        flexWrap="wrap"
        direction={["column", "row"]}
      >
        {/* Logo Placeholder */}
        <Box mb={[4, 0]}>
          <Image src={logo} alt="Logo" boxSize="100px" objectFit="contain" />
        </Box>

        {/* Important Links */}
        <Stack
          direction={["column", "row"]}
          spacing={6}
          mb={[4, 0]}
          textAlign={["center", "left"]}
        >
          <Link
            href="#integration"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Hogyan működik
          </Link>
          <Link
            href="#features"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Funkciók
          </Link>
          <Link
            href="#usage"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Kiknek?
          </Link>
          <Link
            href="#pricing"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Árak
          </Link>
          <Link
            href="#contact"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Kapcsolat
          </Link>
        </Stack>

        {/* Social Media Links */}
        <Stack
          direction="row"
          spacing={4}
          justify={["center", "flex-start"]}
          mb={[4, 0]}
        >
          <IconButton
            icon={<FaFacebook />}
            as="a"
            href="https://facebook.com"
            aria-label="Facebook"
            bg="gray.900"
            color="white"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
          <IconButton
            as="a"
            icon={<FaInstagram />}
            href="https://instagram.com"
            aria-label="Instagram"
            bg="gray.900"
            color="white"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
          <IconButton
            icon={<FaTiktok />}
            as="a"
            href="https://tiktok.com"
            aria-label="Tiktok"
            bg="gray.900"
            color="white"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
          <IconButton
            as="a"
            icon={<FaLinkedin />}
            href="https://linkedin.com"
            aria-label="LinkedIn"
            bg="gray.900"
            color="white"
            size="lg"
            variant="outline"
            _hover={{ bg: "white", color: "gray.900" }}
          />
        </Stack>

        {/* Policy Links */}
        <Stack
          direction={["column", "row"]}
          spacing={6}
          textAlign={["center", "left"]}
        >
          <Link
            href=" https://app.wobble.hu/adatvedelem"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
            >
            Adatkezelési tájékoztató
          </Link>
          <Link
            href=" https://app.wobble.hu/aszf"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Általános Szerződési Feltételek
          </Link>
        </Stack>
      </Flex>

      {/* Copyright */}
      <Box mt={8} textAlign="center">
        <Text fontSize="sm" color={lightColor}>
          &copy; {new Date().getFullYear()} Miller Productions. All rights
          reserved.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
